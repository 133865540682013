<template>
  <v-card
    :class="[
      'rounded-xl elevation-0 py-0 px-0 py-sm-8 px-sm-14',
      { 'custom-wrapper border-secondary': isBreakpointMdAndUp }
    ]"
    max-width="1025px"
  >
    <v-card-title class="d-flex flex-column">
      <a v-bind="redirectHref" target="_blank">
        <v-img
          :src="getAdNetworkImages.small_logo_dark"
          :lazy-src="getAdNetworkImages.small_logo_dark"
          contain
          class="mx-auto"
          max-height="80"
        />
      </a>
      <div class="text-h6 font-weight-medium black--text">
        {{ $t('register.sign_up') }}
      </div>
      <div class="text-subtitle-2">
        <span class="error--text">* </span>
        <span class="black--text opacity-40">
          {{ $t('register.fields_are_required') }}
        </span>
      </div>
    </v-card-title>

    <register-type class="mt-7 mt-sm-11 ml-2" />

    <v-form ref="form">
      <register-form class="mt-9 mt-sm-7" />
    </v-form>

    <v-card-actions class="flex-column">
      <div class="text-uppercase text-center text-caption mt-6">
        <span
          v-for="(registerFooter, index) in registerFooterText"
          :key="index"
        >
          {{ registerFooter.text }}
          <a
            :href="registerFooter.linkHref"
            target="_blank"
          >
            {{ registerFooter.linkText }}
          </a>
        </span>
      </div>

      <vue-recaptcha
        ref="recaptcha"
        :sitekey="settings.googleRecaptchaSitekey"
        :load-recaptcha-script="true"
        :class="['mt-5', { 'recaptcha-invalid': errorIsRecaptcha }]"
        :language="languageRecaptcha"
        @verify="setRecaptcha($event)"
      />

      <c-btn
        :block="isMobile"
        color="primary"
        class="text-subtitle-1 py-3 px-16 mt-6"
        min-height="47"
        :label="$t('register.signup')"
        :loading="isLoading"
        @click="register()"
      />

      <div class="text-subtitle-1 font-weight-medium mt-8">
        {{ $t('register.already_have_an_account') }}
        <v-hover v-slot="{ hover }">
          <span
            :class="['global-cursor-pointer primary--text', { 'primary-lighten--text': hover }]"
            @click="$router.push({ name: routeNames.LOGIN, query: $route.query })"
          >
            {{ $t('register.login') }}
          </span>
        </v-hover>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import { VueRecaptcha } from 'vue-recaptcha'
  import { GTM_EVENTS, GTM_EVENTS_CATEGORY } from '@clickadilla/components/constants/gtm-events.js'
  import { mapActions, mapGetters, mapState } from 'vuex'
  import RegisterType from './RegisterType.vue'
  import RegisterForm from './RegisterForm/Index.vue'
  import gtmPush from '@/services/utils/gtm-push.js'
  import handleErrors from '@/services/handleErrors.js'
  import guardSections from '@/types/guard-sections.js'
  import { getSavedCookieParams, removeCookie } from '@/cookie-queries.js'
  import routeNames from '@/types/route-names.js'

  export default {
    name: 'Register',
    components: {
      RegisterType,
      RegisterForm,
      VueRecaptcha,
      CBtn
    },
    inject: ['settings'],
    data() {
      return {
        routeNames,
        isLoading: false
      }
    },
    computed: {
      ...mapState('register', ['errors']),
      ...mapGetters('register', ['getRegisterData']),
      ...mapGetters('settings', ['getAdNetworkImages', 'helpUrls']),
      ...mapState('settings', ['adNetwork']),
      registerFooterText() {
        return [
          {
            text: this.$t('register.by_clicking_the'),
            linkHref: this.helpUrls.terms_and_conditions,
            linkText: this.$t('register.terms_conditions')
          },
          {
            text: this.$t('register.and'),
            linkHref: this.helpUrls.privacy_policy,
            linkText: this.$t('register.privacy_policy')
          }
        ]
      },
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      },
      isBreakpointMdAndUp() {
        return this.$vuetify.breakpoint.mdAndUp
      },
      errorIsRecaptcha() {
        return !!this.errors['g-recaptcha-response']?.length
      },
      languageRecaptcha() {
        return this.$i18n.locale
      },
      adNetworkHasSectionRegistrationSuccess() {
        return this.adNetwork.guardSections.some(
          ({ name }) => name === guardSections.SUCCESSFUL_REGISTRATION
        )
      },
      adNetworkHasSectionMarketplace() {
        return this.adNetwork.guardSections.some(({ name }) => name === guardSections.FLAT_DEALS)
      },
      redirectHref() {
        return { href: this.helpUrls.landing || false }
      },
      routeParamsForSectionMarketplace() {
        return {
          marketplace: { name: routeNames.MARKETPLACE, query: this.$route.query },
          'flat-deal': { name: routeNames.FLAT_DEAL, params: { id: this.$route.query.id }, query: this.$route.query }
        }[this.$route.query.pageTo]
      },
      redirectIsMarketplace() {
        return !!(this.adNetworkHasSectionMarketplace && this.$route.query.pageTo)
      }
    },
    methods: {
      ...mapActions('register', ['setRecaptcha', 'setErrors']),
      ...mapActions('settings', ['fetchSettings']),
      resetCaptcha() {
        this.$refs.recaptcha.reset()
      },
      async register() {
        if (this.$refs.form.validate()) {
          this.isLoading = true
          this.setErrors({})
          const savedCookieParams = getSavedCookieParams()
          const registrationParamsObjectFromStorage = JSON.parse(sessionStorage.getItem('registration_params'))
          const registrationParamsFromCookies = savedCookieParams.registration_params

          const registrationParamsObjectFromCookies = registrationParamsFromCookies ? JSON.parse(registrationParamsFromCookies) : {}

          const combinedRegistrationParamsObject = {
            ...registrationParamsObjectFromCookies,
            ...registrationParamsObjectFromStorage
          }

          const combinedRegistrationParams = Object.keys(combinedRegistrationParamsObject).length ? combinedRegistrationParamsObject : null

          try {
            const request = {
              ...this.getRegisterData,
              affiliate_code: savedCookieParams.affiliate_code,
              ...(combinedRegistrationParams && { registration_params: combinedRegistrationParams })
            }
            const response = await this.$auth.register(request)
            await Promise.all([
              this.$auth.fetchUser(),
              this.fetchSettings(this.$auth.isAuth)
            ])
            gtmPush({
              event: GTM_EVENTS.REG_FORM_SUBMITTED_SUCCESSFULLY,
              event_category: GTM_EVENTS_CATEGORY.REGISTRATION,
              custom_map: { dimension3: 'UserReg', dimension4: 'Country' },
              Country: response.country_iso_code_3,
              UserReg: response.id,
              user_id: response.id
            })

            this.redirectAfterRegister()
          } catch (error) {
            const errors = handleErrors(error)
            this.setErrors(errors)
            gtmPush({ event: GTM_EVENTS.REG_ERROR })
          }
          this.isLoading = false

          this.resetCaptcha()
        }
      },
      redirectAfterRegister() {
        removeCookie('registration_params')
        removeCookie('affiliate_code')
        const nextRoutingPath = sessionStorage.getItem('next_routing_path')
        sessionStorage.removeItem('next_routing_path')

        if (this.redirectIsMarketplace && this.routeParamsForSectionMarketplace) {
          this.$router.push(this.routeParamsForSectionMarketplace)
          return
        }
        if (nextRoutingPath) {
          this.$router.push({ path: nextRoutingPath })
          return
        }
        if (this.adNetworkHasSectionRegistrationSuccess) {
          this.$router.push({ name: routeNames.REGISTRATION_SUCCEEDED })
          return
        }
        this.$router.push({ name: routeNames.MAIN })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .custom-wrapper {
    padding: 52px 108px !important;
    box-shadow: 8px 8px 40px rgba(0, 0, 0, 0.06) !important;
  }
  .recaptcha-invalid {
    border: 2px solid var(--v-error-base);
    border-radius: 4px;
  }
</style>
